<template>
  <div class="home">
    <section class="home-videos">
      <section class="home-ports">
        <GridCell :type="'port vert'" />
        <GridCell :type="'port vert'" />
        <GridCell :type="'port vert'" />
        <GridCell :type="'port vert'" />
        <GridCell :type="'port vert'" />
        <GridCell :type="'port vert'" />
      </section>
      <section class="home-lands">
        <GridCell :type="'land vert'" />
        <GridCell :type="'land vert'" />
        <GridCell :type="'land vert'" />
        <GridCell :type="'land vert'" />
      </section>
    </section>
    <section class="home-videos">
      <section class="home-ports">
        <GridCell :type="'port vert'" />
        <GridCell :type="'port vert'" />
        <GridCell :type="'port vert'" />
        <GridCell :type="'port vert'" />
        <GridCell :type="'port vert'" />
        <GridCell :type="'port vert'" />
      </section>
      <section class="home-lands">
        <GridCell :type="'land vert'" />
        <GridCell :type="'land vert'" />
        <GridCell :type="'land vert'" />
        <GridCell :type="'land vert'" />
      </section>
    </section>
    <section class="home-videos">
      <section class="home-ports">
        <GridCell :type="'port vert'" />
        <GridCell :type="'port vert'" />
        <GridCell :type="'port vert'" />
        <GridCell :type="'port vert'" />
        <GridCell :type="'port vert'" />
        <GridCell :type="'port vert'" />
      </section>
      <section class="home-lands">
        <GridCell :type="'land vert'" />
        <GridCell :type="'land vert'" />
        <GridCell :type="'land vert'" />
        <GridCell :type="'land vert'" />
      </section>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import GridCell from "@/components/GridCell"

export default {
  name: 'Home',
  components: {
    GridCell,
  }
}
</script>

<style scoped>
.home {
  display: flex;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  flex-wrap: wrap;
}

.home-videos {
  width: 95vw;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  overflow-x: auto;
  overflow-y: hidden;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none; 
}

.home-videos::-webkit-scrollbar { 
  width: 0 !important;
}

.home-videos::-webkit-scrollbar-track {
  border-radius: 0 !important;
  box-shadow: none !important;
}

.home-videos::-webkit-scrollbar-track {
  background: none !important;
}

.home-ports,
.home-lands {
  padding: 10px;
  margin: 5px;
  display: flex;
}


@media only screen and (max-width: 550px) {
  .home {
    padding-left: 0px;
    padding-right: 0px;  
  }
}
</style>
