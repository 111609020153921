<template>
  <section id="new-post" class="new-post">
    <section @click="dismiss" class="dismiss-post">
    </section>
    <section class="post-options">
      <router-link :to="{name: 'Upload', params: {type: 'post'}}"  @click="dismiss"  class="option">
        <svg class="option-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><g fill="none"><path d="M11.275 4.536A4.886 4.886 0 0 1 8.5 13.022V14.5a.5.5 0 1 1-1 0v-1.478a4.886 4.886 0 0 1-2.775-8.486l2.437-2.2a1.25 1.25 0 0 1 1.676 0l2.437 2.2zM8.5 7.501a.5.5 0 0 0-1 0v4.514a3.91 3.91 0 0 0 1 0V7.501z" fill="currentColor"/></g>
        </svg>
        <span>Post</span>
      </router-link>
      <div class="option">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" class="bi bi-collection-play-fill option-svg" viewBox="0 0 16 16">
          <path d="M2.5 3.5a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-11zm2-2a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zm6.258-6.437a.5.5 0 0 1 .507.013l4 2.5a.5.5 0 0 1 0 .848l-4 2.5A.5.5 0 0 1 6 12V7a.5.5 0 0 1 .258-.437z"/>
        </svg>
        <span>Collection</span>
      </div>
      <div class="option">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" class="bi bi-broadcast option-svg" viewBox="0 0 16 16">
          <path d="M3.05 3.05a7 7 0 0 0 0 9.9.5.5 0 0 1-.707.707 8 8 0 0 1 0-11.314.5.5 0 0 1 .707.707zm2.122 2.122a4 4 0 0 0 0 5.656.5.5 0 1 1-.708.708 5 5 0 0 1 0-7.072.5.5 0 0 1 .708.708zm5.656-.708a.5.5 0 0 1 .708 0 5 5 0 0 1 0 7.072.5.5 0 1 1-.708-.708 4 4 0 0 0 0-5.656.5.5 0 0 1 0-.708zm2.122-2.12a.5.5 0 0 1 .707 0 8 8 0 0 1 0 11.313.5.5 0 0 1-.707-.707 7 7 0 0 0 0-9.9.5.5 0 0 1 0-.707zM10 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z"/>
        </svg>
        <span>Session</span>
      </div>
      <div class="option">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" class="bi bi-cash-stack option-svg" viewBox="0 0 16 16">
          <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1H1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
          <path d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2H3z"/>
        </svg>
        <span>Product</span>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  setup() {
    const dismiss = () => {
      document.getElementById("new-post").style.display = "none"
      document.getElementById("close-svg").style.display = "none"
      document.getElementById("add-svg").style.display = "inline" 

      document.getElementsByTagName('body')[0].removeAttribute('style')
    }
    
    return { dismiss }
  }
}
</script>

<style scoped>
.new-post {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100vw;
  height: 100vh;
  display: none;
  z-index: 200;
}

.dismiss-post {
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
}

.light .dismiss-post {
  background: rgb(255, 255, 255, 0.5);
  color: black;
}

.dark .dismiss-post {
  background: rgb(19, 19, 19, 0.5);
  color: white;
}

.post-options {
  display: flex;
  flex-flow: column nowrap;
  width: 30%;
  height: 60%;
  border-radius: 10px;
  border: 2px solid lightgreen;
  position: fixed;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
  z-index: 10;
  text-decoration: none;
}

.light .post-options {
  background: white;
}

.dark .post-options {
  background: rgb(19, 19, 19);
}

.option {
  flex: 1;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  font-size: calc(4px + 1vw);
  line-height: calc(4px + 1vw);
  font-weight: bold;
  border-bottom: 1px solid lightgray;
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.option:hover {
  color: lightgreen;
}

.option:hover svg {
  fill: lightgreen;
}
.option:last-child {
  border-bottom: none;
}


.option-svg {
  margin-right: 10px;
}

.light .option-svg {
  fill: black;
}

.dark .option-svg {
  fill: white;
}

.option span {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-holder {
  width: 45px;
  height: 100px;
  border-radius: 8px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grower-logo {
  width: 100%;
}

@media only screen and (max-width: 550px) {
  .post-options {
    width: 100%;
    height: 80%;
    padding-top: 20px;
    border-radius: 50px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    bottom: 0%;
    left: auto;
    top: auto;
    transform: none;
    padding-bottom: calc(2rem + 2vh);
  }

  .dismiss-post {
    align-items: flex-end;
  }

  .option {
    font-size: calc(14px + 1vw);
    line-height: calc(14px + 1vw);
  }
}

@media only screen and (min-width: 551px) and (max-width: 1100px) and (min-height: 700px) {
  .post-options {
    height: 40%;
    width: 70%;
    top: 50%;
  }

  .option {
    font-size: calc(14px + 1vw);
    line-height: calc(14px + 1vw);
  }
}

</style>