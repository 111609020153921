<template>
  <div class="v-cell-section">
    <section class="v-cell" :class="type" :style="getColor()">
      <video v-if="type == 'land vert'"
        id="my-player"
        class="video-js landscape-video"
        controls
        preload="auto"
        poster="//vjs.zencdn.net/v/oceans.png"
        data-setup='{}'>
        <source src="//vjs.zencdn.net/v/oceans.mp4" type="video/mp4" />
  
        <p class="vjs-no-js">
          To view this video please enable JavaScript, and consider upgrading to a
          web browser that
          <a href="https://videojs.com/html5-video-support/" target="_blank">
            supports HTML5 video
          </a>
        </p>
      </video>
    </section>
    <h2 class="v-cell-title">How to Train Your Dragon</h2>
    <div class="v-cell-reactions">
        <p class="reaction">&#128293; 1K</p>
        <p class="reaction">&#10084;&#65039; 4.5K</p>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  props: ['type'],
  setup(props) {
    const type = ref(props.type)

    const getColor = () => {
      let colors = ["#CA0F11", "#8EC521", "#F1BE22", "#E11A65", "#36A7A7", "#8EC521", "#E11A65", "#36A7A7", "#F1BE22", "#CA0F11"]
      var color = colors[Math.floor(Math.random()*colors.length)];

      let filter = `filter: drop-shadow(0 0 4px ${color})`
      return filter
    }

    return { type, getColor}
  }
}
</script>
<style scoped>

.v-cell {
  border: 1px solid black;
  border-radius: 10px;
  background-color: black;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-cell.land {
  width: calc(16rem + 4vw);
  height: calc(10rem + 2vh);
}

.v-cell-section {
  display: flex;
  flex-direction: column;
}

.v-cell-title,
.v-cell-reactions {
  font-size: 14px;
  width: 65%;
  text-align: left;
  margin: 10px;
}

.v-cell-reactions {
  display: flex;
  justify-content: flex-start;
  width: 60%;
  margin: 10px;
  margin-top: 5px;
}

#my-player .landscape-video {
  height: 200%;
  width: 200%;
  margin: 0 auto;
  cursor: none;
}
.reaction {
  margin-right: 6px;
}

.cell-details {
  position: fixed;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  padding-top: calc(8rem + 4vh);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0%;
  left: 0%;
}

.light .cell-details {
  background-color: rgb(255, 255, 255, 0.5);
}

.dark .cell-details {
  background-color: rgb(16, 16, 16, 0.5);
}

.light .detail {
  border: 1px solid black;
  color: black;
  background-color: white;
}

.dark .detail {
  border: 1px solid white;
  color: white;
  background-color: rgb(16, 16, 16);
}

.detail {
  z-index: 3;
  position: fixed;
  height: 75%;
  width: 80%;
  left: 50%;
  top: calc(8rem + 4vh);
  margin-left: -40%;
}

.v-cell.port.vert {
  height: calc(16rem + 2vh);
  width: calc(10rem + 2vw);
}

.v-cell.port.vert,
.v-cell.land.vert {
  margin-left: 5px;
  margin-right: 5px;
}


@media only screen and (max-width: 330px) {
  .v-cell.land {
    width: calc(13rem + 4vw);
    height: calc(7rem + 2vh);
  }
}

@media only screen and (max-width: 500px) and (min-height: 600px) {
  .v-cell.land {
    width: calc(16rem + 4vw);
    height: calc(9rem + 2vh);
  }
}

@media only screen and (min-width: 331px) and (max-width: 400px) and (max-height: 580px) {
  .v-cell.land {
    width: calc(13rem + 4vw);
    height: calc(7rem + 2vh);
  }
}

@media only screen and (min-width: 1200px) {
  .detail {
    height: 70%;
  }
}

@media only screen and (max-width: 550px) {
  .detail {
    top: 0%;
    height: 100%;
    padding-top: calc(5rem + 4vh);
  }

  .cell-details {
    padding-top: calc(4rem + 4vh);
  }
}
</style>