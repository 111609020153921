import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/explore',
    name: 'Explore',
    component: function () {
      return import(/* webpackChunkName: "explore" */ '../views/Explore.vue')
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: function () {
      return import(/* webpackChunkName: "profile" */ '../views/Profile.vue')
    }
  },
  {
    path: '/upload/:type',
    name: 'Upload',
    props: true,
    component: function () {
      return import(/* webpackChunkName: "upload" */ '../views/Upload.vue')
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
