<template>
  <main class="main" :class="viewMode">
    <nav class="navbar" id="nav" aria-label="main navigation bar">
      <section class="navbar-logo-holder">
        <img class="navbar-logo" src="@/assets/images/growerganglogo.png" alt="Grower Gang Logo" />
      </section>
      <section class="navbar-options-holder">
        <svg id="add-svg" @click="showNewPost" xmlns="http://www.w3.org/2000/svg" class="bi bi-plus-circle option-add" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"></path>
        </svg>
        <svg id="close-svg" @click="dismiss" xmlns="http://www.w3.org/2000/svg" class="bi bi-x-circle option-add" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
        <router-link v-if="showProfile" to="/profile" id="profile-btn">
          <svg xmlns="http://www.w3.org/2000/svg" class="bi bi-person-circle option-account" viewBox="0 0 16 16">
            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
            <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
          </svg>
        </router-link>
        <span class="username" id="username"> @modernlabrat </span>
      </section>
    </nav> 
    <nav class="tabbar" id="tabbar" aria-label="main tab bar">
      <router-link to="/" class="tabbar-link" @click="updateActive('/')">
        <svg id="home-svg" xmlns="http://www.w3.org/2000/svg" class="bi bi-house tabbar-svg" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/>
          <path fill-rule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"/>
        </svg>
        <span id="home-title" class="tabbar-title">Home</span>
      </router-link>
      <router-link to="/explore" class="tabbar-link" @click="updateActive('/explore')">
        <svg id="explore-svg" xmlns="http://www.w3.org/2000/svg" class="bi bi-globe tabbar-svg" viewBox="0 0 16 16">
          <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z"/>
        </svg>
        <span id="explore-title" class="tabbar-title">Explore</span>
      </router-link>
    </nav>
    <router-view class="router-view" id="main-router">
    </router-view>
    <NewPost v-if="showing" />
  </main>
</template>

<script>
import { ref, computed } from "vue"
import { useRoute, useRouter } from "vue-router"
import NewPost from "@/components/NewPost"

export default {
  components: {
    NewPost
  },
  setup() {
    const viewMode = ref("light")
    let current = window.location.pathname;
    let showTitles = (window.innerWidth > 500) ? true : false;
    const showProfile = (true)
    const router = useRouter()
    const showing = ref(false)

    router.beforeEach((to, from, next) => {
      let username = document.getElementById("username")
      
      if (to.name == 'Profile') {
        document.getElementById("profile-btn").style.display = "none";
        if (username)
          username.style.display = "inline";
      } else {
        document.getElementById("profile-btn").style.display = "inline";
        document.getElementById("username").style.display = "none";
      }

      next()
    })

    const updateFill = () => {
      const svgElements = [
        document.getElementById("home-svg"),
        document.getElementById("explore-svg")
      ]

      const titleElements = [
        document.getElementById("home-title"),
        document.getElementById("explore-title")
      ]
      
      svgElements.forEach((svg) => {
        if (viewMode.value == "light")
          svg.style.fill = "black"
        else
          svg.style.fill = "white"
      })

      if (showTitles) {
        titleElements.forEach((title) => {
          if (viewMode.value == "light")
            title.style.color = "black"
          else
            title.style.color = "white"
        })
      }
    }

    var style = document.createElement("style");

    function hideScroll() {
      style.innerHTML = `body::-webkit-scrollbar {display: none;}`;
      document.getElementById('app').appendChild(style);
    }

    const showNewPost = () => {
      showing.value = true

      setTimeout(() => {
        document.getElementById("new-post").style.display = "flex"
        document.getElementById("new-post").addEventListener('touchmove', dismiss, false);
        document.getElementById("add-svg").style.display = "none"      
        document.getElementById("close-svg").style.display = "flex"  
      }, 100);    
    }

    const dismiss = () => {
      document.getElementById("new-post").style.display = "none"
      document.getElementById("close-svg").style.display = "none"
      document.getElementById("add-svg").style.display = "inline"

      showing.value = false
    }

    const updateActive = (pathName) => {
      updateFill()

      if (pathName == "/") {
        document.getElementById("home-svg").style.fill = "rgb(52, 168, 52)"

        if (showTitles)
          document.getElementById("home-title").style.color = "rgb(52, 168, 52)"
      } else if (pathName == "/explore") {
        document.getElementById("explore-svg").style.fill = "rgb(52, 168, 52)"

        if (showTitles)
          document.getElementById("explore-title").style.color = "rgb(52, 168, 52)"
      }
    }

    window.onload = function(){
      updateActive(current)
    }

    

    return { viewMode, updateActive, showNewPost, dismiss, showProfile, showing }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

#app .light {
  background-color: white;
  color: black;
}

#app .dark {
  background-color: rgb(19, 19, 19);
  color: white;
}

/* width */

html::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

/* Track */
html::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(109, 109, 109);
  border-radius: 10px;
}

/* Handle */
html::-webkit-scrollbar-thumb {
  background: rgb(53, 53, 53);
  border-radius: 10px;
}



.username {
  padding: 4px 6px;
  background: lightgreen;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  margin-left: 10px;
}

#nav, #tabbar {
  position: fixed;
  top: 0%;
  height: calc(4rem + 2vh);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 5;
}

#nav {
  justify-content: space-between;
  background-image: url('./assets/images/growergangbg.png');
  background-size: cover;
}

#tabbar {
  top: calc(3.75rem + 2vh);
  height: calc(3rem + 1vh);
}

#close-svg {
  display: none;
}

.light #nav,
.light #tabbar {
  background-color: #fafafa;
}

.light #tabbar {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.light #nav {
  border-bottom: 1px solid black;
}

.dark #nav,
.dark #tabbar {
  background-color: rgb(19, 19, 19);
}

.dark #tabbar {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}

.dark #nav {
  border-bottom: 1px solid white;
}

.tabbar-link {
  flex-grow: 1;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.light .tabbar-link {
  color: black;
}

.dark .tabbar-link {
  color: white;
}

.dark .tabbar-svg,
.dark .option-add,
.dark .option-account {
  fill: white;
}


#home-svg:hover,
#explore-svg:hover,
.option-account:hover,
.option-account:focus,
.option-add:hover,
.option-add:focus {
  fill:rgb(52, 168, 52);
}

.tabbar-link:hover a,
.tabbar-link:hover span {
  color: rgb(52, 168, 52);
}

.navbar-logo {
  width: calc(2rem + 1vw);
}

.dark .navbar-logo {
  background-color: rgba(49, 49, 49, 0.4);
  border-radius: 50%;
}

.navbar-logo-holder,
.navbar-options-holder {
  width: 50%;
  display: flex;
  align-items: center;
}

.navbar-logo-holder {
  justify-content: flex-start;
  padding-left: 20px;
}

.navbar-options-holder {
  justify-content: flex-end;
  flex-direction: row;
  padding-right: 20px;
}

.router-view {
  padding-top: calc(8rem + 6vh);
  width: 100%;
  margin: 0 auto;
  padding-bottom: calc(2rem + 2vh);
  position: relative;
}

.option-add,
.tabbar-svg {
  width: calc(.7rem + 1vw);
  cursor: pointer;
}

.tabbar-svg {
  margin-right: 8px;
}

.option-account {
  width: calc(1.4rem + 1vw);
  margin-left: 12px;
  cursor: pointer;
}

@media only screen and (max-width: 550px) {
  #tabbar {
    top: auto;
    bottom: 0%;
  }

  .router-view {
    padding-top: calc(6rem + 2vh);
  }

  .tabbar-title {
    display: none;
  }

  .option-add,
  .tabbar-svg {
    width: calc(1rem + 3vw);
  }

  .option-account {
    width: calc(1.8rem + 2vw);
  }

  .navbar-logo {
    width: calc(2rem + 4vw);
  }
}

/*@media only screen and (orientation:portrait){
  body {
    height: 100vw;
    transform: rotate(90deg);
  }
}*/
</style>
